import Vue from 'vue'
import App from './App.vue'
import store from './store'
import VueRouter from 'vue-router' // 引入vue-router
import '../static/res/js/rem'

import Home from '@/views/Home'

import urlParams from './utils/UrlParams'
import hhstore from './utils/HHStore'
import help from './utils/Help'
import aes from './utils/Aes'

Vue.config.productionTip = false
Vue.config.ignoredElements = [
	'wx-open-launch-weapp'
]

Vue.use(VueRouter) //Vue全局使用Router
// Vue.use(Toast);


const routes = [{
	path: '/',
	component: Home,
	meta: {
		title: '首页',
		showTab: false
	}
}]

const router = new VueRouter({
	mode: 'history',
	routes
})

router.beforeEach((toRoute, fromRoute, next) => {
	window.document.title = toRoute.meta && toRoute.meta.title ? toRoute.meta.title : '';
	help.getdot()
	next();
})



new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app')


