<script>
function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toGMTString();
    document.cookie = cname + "=" + cvalue + "; " + expires;
}

function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i].trim();
        if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
    }
    return "";
}


export default {
    // 如果v是null或者undefined，和del效果相同
    set(k, v) {
        
        if (!v || typeof(v) == "undefined") {
            this.del(k)
            return
        }

        try {
            localStorage.setItem(k, v);
        } catch (e) {
            // console.info(e)
        }
		
        try {
            sessionStorage.setItem(k, v);
        } catch (e) {
            // console.info(e)
        }
		
        setCookie(k, v, 360 * 1000)
    },
    get(k) {
        

        try {
            var ls = localStorage.getItem(k)
        } catch (e) {
            // console.info(e)
        }
		
        try {
            var ss = sessionStorage.getItem(k)
        } catch (e) {
            // console.info(e)
        }

        var cs = getCookie(k)
        var ret = ls ? ls : (ss ? ss : (cs ? cs : null))

        // 重新写值
        if (ret) {
            this.set(k, ret)
        }

        return ret
    },
    del(k) {
        

        try {
            localStorage.removeItem(k);
        } catch (e) {
            console.info(e)
        }
        try {
            sessionStorage.removeItem(k);
        } catch (e) {
            console.info(e)
        }

        setCookie(k, '', -1)
    }
}
</script>